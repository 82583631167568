export const initialAbility = [
  {
      "action": "read",
      "subject": "recruiter_report"
  },
  {
      "action": "read",
      "subject": "interview"
  },
  {
    "action": "read",
    "subject": "pofu"
  },
  {
      "action": "read",
      "subject": "my_candidates"
  },
  {
      "action": "read",
      "subject": "report"
  },
  {
      "action": "read",
      "subject": "jobs"
  },
  {
      "action": "read",
      "subject": "auth"
  },
  {
      "action": "manage",
      "subject": "auth"
  }
]

export const _ = undefined
