import useJwt from '@/auth/jwt/useJwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
console.log("logged in status", localStorage.getItem('userData'))
export const isUserLoggedIn = () => {
  const expireIn = localStorage.getItem('expireIn')
  //console.log("expireIN", expireIn)
  //console.log("now Date", Math.floor(Date.now() / 1000))
   if (Math.floor(Date.now() / 1000 > expireIn)) {
      window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
      //console.log("called logout")
      localStorage.clear();
      return false
   } 
   else {
    return true 
   }
    
  //return localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
const lastPageUrl = window.sessionStorage.getItem('lastPage');
console.log("getuserdata",getUserData, lastPageUrl)
/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
//console.log("userRole", userRole)
export const getHomeRouteForLoggedInUser = userRole => {
  const lastPageUrl = window.sessionStorage.getItem('lastPage');
  //console.log()
  if (userRole === 'ADMIN' && (!lastPageUrl || lastPageUrl === '/login')) return '/' 
  if (userRole === 'MASTER' && (!lastPageUrl || lastPageUrl === '/login')) return '/' 
  if (userRole === 'HR' && (!lastPageUrl || lastPageUrl === '/login')) return '/' 
  if (userRole === 'MIS' && (!lastPageUrl || lastPageUrl === '/login')) return '/' 
  if (userRole === 'TALENT-ADVISOR' && (!lastPageUrl || lastPageUrl === '/login')) return '/' 
  if (userRole === 'ACCOUNT-MANAGER' && (!lastPageUrl || lastPageUrl === '/login')) return '/' 
  if (userRole === 'TALENT-ADVISOR-SOURCER' && (!lastPageUrl || lastPageUrl === '/login')) return '/' 
  if (userRole === 'PROGRAM-MANAGER' && (!lastPageUrl || lastPageUrl === '/login')) return '/'
  if (userRole === 'HR-HEAD' && (!lastPageUrl || lastPageUrl === '/login')) return '/'  
  if (userRole === 'ACCOUNTANT' && (!lastPageUrl || lastPageUrl === '/login')) return '/'

  if (userRole === 'VENDOR-ACCOUNT-MANAGER' && (!lastPageUrl || lastPageUrl === '/login')) return '/apps/jobs'
  if (userRole === 'VENDOR-RECRUITER' && (!lastPageUrl || lastPageUrl === '/login')) return '/apps/jobs'
  /*if (userRole === 'MASTER') return { name: 'access-control' }
  if (userRole === 'HR') return { name: 'access-control' }
  if (userRole === 'manager') return { name: 'access-control' }
  if (userRole === 'MODERATOR') return { name: 'access-control' }*/
  // return { name: 'auth-login' }
  return '/'
}