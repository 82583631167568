import { StarIcon } from "vue-feather-icons";
import ability from '@/libs/acl/ability'
// console.log('check apps list ability: ', ability.can('read', 'jobs'), ability)
export default [
  {
    header: 'Apps & Pages',
    resource: 'apps_list',
    action: ability.can('read', 'apps_list') === true ? 'read' : 'manage'
    // action: 'read'
  },
  {
    title: 'Jobs',
    icon: 'BriefcaseIcon',
    route: 'apps-jobs',
    resource: 'jobs',
    // action: 'read',
    action: ability.can('read', 'jobs') === true ? 'read' : 'manage'
  },
  /* {
    title: 'User',
    icon: 'UserIcon',
    children: [
      {
        title: 'List',
        route: 'apps-users-list',
      },
      {
        title: 'View',
        route: { name: 'apps-users-view', params: { id: 21 } },
      },
      {
        title: 'Edit',
        route: { name: 'apps-users-edit', params: { id: 21 } },
      },
    ],
  }, */
  
  // {
  //   title: 'Add Candidates',
  //   icon: 'UserIcon',
  //   route: 'apps-candidates-add',
  // },
  // {
  //   title: 'Candidates DB',
  //   icon: 'UserIcon',
  //   route: 'apps-candidates-search',
  // },
  // {
  //   title: 'Todo',
  //   route: 'apps-todo',
  //   icon: 'CheckSquareIcon',
  // },
  // {
  //   title: 'Calendar',
  //   route: 'apps-calendar',
  //   icon: 'CalendarIcon',
  // },
  
  
  // {
  //   title: 'LinkedIn Job Post',
  //   route: 'apps-linkedin-job-post',
  //   icon: 'LinkedinIcon',
  // },
  {
    title: 'My Candidates',
    route: 'apps-my-candidates',
    icon: 'UsersIcon',
    resource: 'my_candidates',
    action: ability.can('read', 'my_candidates') === true ? 'read' : 'manage'
  },
  // {
  //   title: 'Train N Hire',
  //   route: 'apps-train-n-hire',
  //   icon: 'LayoutIcon',
    
  // },
  // {
  //   title: 'Interview & POFU Tracker',
  //   icon: 'LayoutIcon',
  //   children: [
  //     {
  //       title: 'Interview Tracker',
  //       route: 'apps-interview-tracker',
  //       icon: 'CalendarIcon',
  //     },
  //     {
  //       title: 'POFU Tracker',
  //       route: 'apps-pofu-tracker',
  //       icon: 'ShoppingBagIcon',
  //     },
  //   ],
  // },
  {
    title: 'Interview Tracker',
    route: 'apps-interview-tracker',
    icon: 'CalendarIcon',
    resource: 'interview',
    action: ability.can('read', 'interview') === true ? 'read' : 'manage'
  },
  {
    title: 'POFU Tracker',
    route: 'apps-pofu-tracker',
    icon: 'ShoppingBagIcon',
    resource: 'pofu',
    action: ability.can('read', 'pofu') === true ? 'read' : 'manage'
  },
  {
    title: 'Candidates DB',
    icon: 'LayoutIcon',
    resource: 'boolean_search',
    action: ability.can('read', 'boolean_search') === true ? 'read' : 'manage',
    action: 'read',
    children: [
      {
        title: 'Candidates Boolean Search',
        route: 'apps-candidates-search',
        resource: 'boolean_search',
        action: ability.can('read', 'boolean_search') === true ? 'read' : 'manage',
      },
      {
        title: 'Add Candidates',
        route: 'apps-candidates-add',
        resource: 'candidates',
        action: ability.can('read', 'boolean_search') === true ? 'read' : 'manage',
      },
      // {
      //   title: 'Status Wise Tracker',
      //   route: 'apps-reports-status-wise',
      // },
    ],
  },
  {
    title: 'Reports',
    icon: 'LayoutIcon',
    resource: 'report',
    action: ability.can('read', 'report') === true || ability.can('read', 'recruiter_report') === true ? 'read' : 'manage',
    children: [
      {
        title: 'Recruiter Worktracker',
        route: 'apps-reports-recruiter',
        resource: 'recruiter_report',
        action: ability.can('read', 'report') === true || ability.can('read', 'recruiter_report') === true ? 'read' : 'manage'
      },
      // {
      //   title: 'Sourcer Worktracker',
      //   route: 'apps-reports-sourcer',
      // },
      {
        title: 'Status Wise Tracker',
        route: 'apps-reports-status-wise',
        resource: 'statuswise_report',
        action: ability.can('manage', 'report') === true || ability.can('read', 'statuswise_report') === true ? 'read' : 'manage'
      },
    ],
  },
  // {
  //   title: 'Interview Tracker',
  //   route: 'apps-interview-tracker',
  //   icon: 'CalendarIcon',
  // },
  // {
  //   title: 'POFU Tracker',
  //   route: 'apps-pofu-tracker',
  //   icon: 'ShoppingBagIcon',
  // },
  {
    title: 'MIS Apps',
    icon: 'LayoutIcon',
    // resorce: 'mis',
    // action: 'read',
    children: [
      {
        title: 'Attendance Tracker',
        route: 'apps-attendance',
        resource: 'attendance',
        action: ability.can('read', 'attendance') === true ? 'read' : 'manage'
      },
      {
        title: 'LinkedIn Job Post',
        route: 'apps-linkedin-job-post',
        icon: 'LinkedinIcon',
        resource: 'linkedin_post',
        action: ability.can('read', 'linkedin_post') === true ? 'read' : 'manage'
      },
      {
        title: 'Invoice Tracker',
        route: 'apps-invoice-tracker',
        icon: 'DollarSignIcon',
        resource: 'invoice',
        action: ability.can('read', 'invoice') === true ? 'read' : 'manage'
      },
      {
        title: 'Expense Tracker',
        route: 'apps-expense-tracker',
        icon: 'ZapIcon',
        resource: 'expense',
        action: ability.can('read', 'expense') === true ? 'read' : 'manage'
      },
    ],
  },
  // {
  //   title: 'Invoice Tracker',
  //   route: 'apps-invoice-tracker',
  //   icon: 'DollarSignIcon',
  // },
  {
    title: 'Stack Ranking',
    route: 'apps-stack-ranking',
    icon: 'StarIcon',
    resource: 'stack_ranking',
    action: ability.can('read', 'stack_ranking') === true ? 'read' : 'manage'
  },
  // {
  //   title: 'Attendance Tracker',
  //   route: 'apps-attendance',
  //   icon: 'UserCheckIcon'
  // },
  
  {
    title: 'Employee Appraisal',
    route: 'apps-employee-appraisal',
    icon: 'ApertureIcon',
    resource: 'appraisals',
    action: ability.can('read', 'appraisals') === true ? 'read' : 'manage'
  },
  {
    title: 'HR Apps',
    route: 'apps-expense-tracker',
    icon: 'LayoutIcon',
    resource: 'hr',
    action: ability.can('read', 'hr') === true ? 'read' : 'manage',
    children: [
      {
        title: 'Attendance Tracker',
        route: 'apps-attendance',
        resource: 'attendance',
        action: ability.can('read', 'attendance') === true ? 'read' : 'manage'
      },
      
    ],
  },
  {
    title: 'Client Recruiters List',
    route: 'apps-client-recruiters-list',
    icon: 'UserIcon',
    resource: 'client_recruiter',
    action: ability.can('read', 'client_recruiter') === true ? 'read' : 'manage'
  },
  // {
  //   name: 'Vendor Registration',
  //   route: 'VendorRegistration',
  //   resource: 'vendor-registration',
  //   // action: 'read'
  //   // component: VendorRegistration,
  // },
  // {
  //   name: 'Vendor Registration',
  //   name: 'EditVendor',
  //   resource: 'vendor-registration',
  //   // action: 'read'
  //   // component: VendorRegistration,
  //   // props: true,
  // },
  // {
  //   title: 'Policy Documents',
  //   route: 'policy-documents',
  //   icon: 'BookOpenIcon'
  // },
  
  // {
  //   title: 'Candidate Evaluation',
  //   route: 'apps-candidate-evaluation',
  //   icon: 'UserIcon'
  // },

  // apps-client-recruiters-list
  
  //
  // {
  //   title: 'Recruiter Work Tracker',
  //   route: 'dashboard-tracker',
  //   icon: 'CalendarIcon',
  // },
  // {
  //   title: 'Sourcer Work Tracker',
  //   route: 'sourcer-tracker',
  //   icon: 'ActivityIcon',
  // },
]
