import router from '../index'

export default [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
    meta: {
      pageTitle: 'Calendar',
      resource: 'calendar',
      action: 'read'
    },
    
  },
  {
    path: '/apps/jobs',
    name: 'apps-jobs',
    component: () => import('@/views/apps/jobs/Jobs.vue'),
    meta: {
      pageTitle: 'Jobs',
      resource: 'jobs',
      action: 'read',
      breadcrumb: [
        {
          text: 'Jobs',
          active: true,
        },
      ],
    },
    
    
  },
  {
    path: '/apps/add-job-old-flow',
    name: 'apps-add-job-old-flow',
    component: () => import('@/views/apps/jobs/AddJob_oldflow_1nov22.vue'),
    meta: {
      pageTitle: 'Add Job',
      resource: 'jobs',
      action: 'read',
      breadcrumb: [
        {
          text: 'Jobs',
          to: { name: 'apps-jobs' }
        },
        {
          text: 'Add Job',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/add-job',
    name: 'apps-add-job',
    component: () => import('@/views/apps/jobs/AddJob.vue'),
    meta: {
      pageTitle: 'Add Job',
      resource: 'jobs',
      breadcrumb: [
        {
          text: 'Jobs',
          to: { name: 'apps-jobs' }
        },
        {
          text: 'Add Job',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/view-job/:id',
    name: 'apps-view-job',
    component: () => import('@/views/apps/jobs/jobs-view/JobsView.vue'),
    meta: {
      pageTitle: 'View Jobs',
      resource: 'jobs',
      breadcrumb: [
        {
          text: 'Jobs',
          to: { name: 'apps-jobs' }
        },        
        {
          text: 'View Job',
          active: true,
          //path: '/apps/view-job/:id',
        },
      ],
    },
    
  },
  
  {
    path: '/apps/edit-job/:id',
    name: 'apps-edit-job',
    component: () => import('@/views/apps/jobs/EditJob.vue'),
    meta: {
      pageTitle: 'Edit Job',
      resource: 'jobs',
      breadcrumb: [
        {
          text: 'Jobs',
          to: { name: 'apps-jobs' }
        },
        {
          text: 'Edit Job',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/edit-job-v2/:id',
    name: 'apps-edit-job-sourcer',
    component: () => import('@/views/apps/jobs/EditJob_OldFlow_1nov22.vue'),
    meta: {
      pageTitle: 'Edit Job Sourcer',
      resource: 'jobs',
      breadcrumb: [
        {
          text: 'Jobs',
          to: { name: 'apps-jobs' }
        },
        {
          text: 'Edit Job Sourcer',
          active: true,
        },
      ],
    },
  },
  
  // *===============================================---*
  // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/todo',
    name: 'apps-todo',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      // resource: 'Todo',
    },
  },
  {
    path: '/apps/todo/:filter',
    name: 'apps-todo-filter',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      // resource: 'Todo',
    },
    beforeEnter(to, _, next) {
      if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/todo/tag/:tag',
    name: 'apps-todo-tag',
    component: () => import('@/views/apps/todo/Todo.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'todo-application',
      navActiveLink: 'apps-todo',
      // resource: 'Todo',
    },
    beforeEnter(to, _, next) {
      if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
      else next({ name: 'error-404' })
    },
  },
  
  {
    path: '/apps/candidates/list',
    name: 'apps-candidates-list',
    component: () => import('@/views/apps/candidate/candidates-list/CandidateList.vue'),
    meta: {
      pageTitle: 'Candidates',
      resource: 'candidates',
      breadcrumb: [
        
      ],
    },
  },
  {
    path: '/apps/candidates/list-all',
    name: 'apps-candidates-list-all',
    component: () => import('@/views/apps/candidate/candidates-list/CandidateListAll.vue'),
    meta: {
      pageTitle: 'Candidates',
      resource: 'candidates',
      breadcrumb: [
        
        {
          text: 'Candidates',
          active: true,
        },
        
      ],
    },
  },
  {
    path: '/apps/candidates/add/:id',
    name: 'apps-candidates-list-add',
    component: () => import('@/views/apps/candidate/candidates-list/CandidateListAdd.vue'),
    meta: {
      pageTitle: 'Candidates',
      resource: 'candidates',
      breadcrumb: [
        {
          text: 'Candidates Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/candidates/add/',
    name: 'apps-candidates-add',
    component: () => import('@/views/apps/candidate/candidates-list/CandidateListAddFreePool.vue'),
    meta: {
      pageTitle: 'Candidates',
      resource: 'candidates',
      breadcrumb: [
        {
          text: 'Candidates Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/candidates/view/:id/:jobId',
    name: 'apps-candidates-view',
    component: () => import('@/views/apps/candidate/candidates-view/CandidatesView.vue'),
    meta: {
      pageTitle: 'View Candidate',
      resource: 'candidates',
        
    },
  },
  {
    path: '/apps/candidates/edit/:id/:jobId',
    name: 'apps-candidates-edit',
    component: () => import('@/views/apps/candidate/candidates-edit/CandidateEdit.vue'),
    meta: {
      pageTitle: 'Edit Candidate',
      resource: 'candidates',
    },
  },
  {
    path: '/apps/reports/Recruiter.vue',
    name: 'apps-reports-recruiter',
    component: () => import('@/views/apps/reports/Recruiter.vue'),
    meta: {
      resource: 'recruiter_report',
      action: 'read'
    },
  },
  // {
  //   path: '/apps/reports/Sourcer.vue',
  //   name: 'apps-reports-sourcer',
  //   component: () => import('@/views/apps/reports/Sourcer.vue'),
  //   meta: {
  //     resource: 'Reports',
  //   },
  // },
  {
    path: '/apps/reports/StatusWiseTracker',
    name: 'apps-reports-status-wise',
    component: () => import('@/views/apps/reports/StatusWiseTracker.vue'),
    meta: {
      resource: 'statuswise_report',
      action: 'read'
    },
  },
  {
    path: '/apps/linkedin-job-post',
    name: 'apps-linkedin-job-post',
    component: () => import('@/views/apps/job-post/LinkedInPost.vue'),
    meta: {
      pageTitle: 'LinkedIn Job Post',
      resource: 'linkedin_post',
      breadcrumb: [
        {
          text: 'LinkedIn Job Post',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/my-candidates',
    name: 'apps-my-candidates',
    component: () => import('@/views/apps/candidate/candidates-list/MyCandidates.vue'),
    meta: {
      pageTitle: 'My Candidates',
      resource: 'my_candidates',
      action: 'read',
      breadcrumb: [
        {
          text: 'My Candidates',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/train-n-hire',
    name: 'apps-train-n-hire',
    component: () => import('@/views/apps/train-n-hire/TrainNHire.vue'),
    meta: {
      pageTitle: 'Train N Hire',
      // resource: 'TrainNHire',
      breadcrumb: [
        {
          text: 'Train N Hire',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/train-n-hire/add-batch',
    name: 'apps-train-n-hire-add-batch',
    component: () => import('@/views/apps/train-n-hire/AddBatch.vue'),
    meta: {
      pageTitle: 'Add Batch',
      breadcrumb: [
        {
          text: 'Train N Hire',
          active: true
        },
        {
          text: 'Add Batch',

        },
      ],
    },
  },
  {
    path: '/apps/train-n-hire/add-candidate/:id',
    name: 'apps-train-n-hire-add-candidate',
    component: () => import('@/views/apps/train-n-hire/AddCandidateToBatch.vue'),
    meta: {
      pageTitle: 'Add Candidate To Batch',
      breadcrumb: [
        {
          text: 'Train N Hire',
          active: true
        },
        {
          text: 'Add Candidate To Batch',

        },
      ],
    },
  },{
    path: '/apps/train-n-hire/view/:id',
    name: 'apps-train-n-hire-view',
    component: () => import('@/views/apps/train-n-hire/BatchView.vue'),
    meta: {
      pageTitle: 'View Training Batch',
      breadcrumb: [
        {
          text: 'Train N Hire',
          active: true
        },
        {
          text: 'Training Batch View',

        },
      ]  
    },
  },
  {
    path: '/apps/interview-tracker',
    name: 'apps-interview-tracker',
    component: () => import('@/views/apps/interview/InterviewTracker.vue'),
    meta: {
      pageTitle: 'Interview Tracker',
      resource: 'interview',
      breadcrumb: [
        {
          text: 'Interview Tracker',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/pofu-tracker',
    name: 'apps-pofu-tracker',
    component: () => import('@/views/apps/pofu/POFUTracker.vue'),
    meta: {
      pageTitle: 'POFU Tracker',
      resource: 'pofu',
      breadcrumb: [
        {
          text: 'POFU Tracker',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/invoice-tracker',
    name: 'apps-invoice-tracker',
    component: () => import('@/views/apps/invoice/InvoiceTracker.vue'),
    meta: {
      pageTitle: 'Invoice Tracker',
      resource: 'invoice',
      action: 'read',
      breadcrumb: [
        {
          text: 'Invoice Tracker',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/apps/create-invoice1',
  //   name: 'apps-create-invoice1',
  //   component: () => import('@/views/apps/invoice/CreateInvoice_NA.vue'),
  //   meta: {
  //     pageTitle: 'Create Invoice1',
  //     resource: 'Create Invoice1',
  //     breadcrumb: [
  //       {
  //         text: 'Create Invoice1',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/apps/create-invoice',
    name: 'apps-create-invoice',
    component: () => import('@/views/apps/invoice/CreateInvoice.vue'),
    meta: {
      pageTitle: 'Create Invoice',
      resource: 'invoice',
      action: 'create',
      breadcrumb: [
        {
          text: 'Create Invoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/update-invoice/:id',
    name: 'apps-update-invoice',
    component: () => import('@/views/apps/invoice/UpdateInvoice.vue'),
    meta: {
      pageTitle: 'Update Invoice',
      resource: 'invoice',
      action: 'update',
      breadcrumb: [
        {
          text: 'Update Invoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/stack-racking/',
    name: 'apps-stack-ranking',
    component: () => import('@/views/apps/stack-ranking/StackRanking.vue'),
    meta: {
      pageTitle: 'Stack Ranking',
      resource: 'stack_ranking',
      breadcrumb: [
        {
          text: 'Stack Ranking',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/attendance/',
    name: 'apps-attendance',
    component: () => import('@/views/apps/attendance/Attendance.vue'),
    meta: {
      pageTitle: 'Attendance',
      resource: 'attendance',
      breadcrumb: [
        {
          text: 'Attendance',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/emp-communication/',
    name: 'apps-emp-communication',
    component: () => import('@/views/pages/profile/EmpCommunication.vue'),
    meta: {
      pageTitle: 'Employee Communication',
      resource: 'employee_communication',
      action: 'read',
      breadcrumb: [
        {
          text: 'Employee Communication',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/bulk-mailer/',
    name: 'apps-bulk-mailer',
    component: () => import('@/views/apps/bulk-mailer/BulkMailer.vue'),
    meta: {
      pageTitle: 'Bulk Mailer',
      resource: 'bulk_mailer',
      action: 'read',
    },
  },
  // 'apps-emp-grievance
  {
    path: '/apps/candidates-search/',
    name: 'apps-candidates-search',
    component: () => import('@/views/apps/candidate-db/CandidateDB.vue'),
    meta: {
      pageTitle: 'Candidates DB',
      resource: 'boolean_search',
      breadcrumb: [
        
        {
          text: 'Candidates DB',
          active: true,
        },
        
      ],
    },
  },
  {
    path: '/apps/employee-appraisal/',
    name: 'apps-employee-appraisal',
    component: () => import('@/views/apps/appraisal/EmployeeAppraisal.vue'),
    meta: {
      pageTitle: 'Employee Appraisal',
      // resource: 'Employee Appraisal',
      breadcrumb: [
        
        {
          text: 'Employee Appraisal',
          active: true,
        },
        
      ],
    },
  },
  {
    path: '/apps/expense-tracker/',
    name: 'apps-expense-tracker',
    component: () => import('@/views/apps/expense/ExpenseTracker.vue'),
    meta: {
      pageTitle: 'Expense Tracker',
      resource: 'expense',
      action: 'read',
      breadcrumb: [
        
        {
          text: 'Expense Tracker',
          active: true,
        },
        
      ],
    },
  },
  {
    path: '/apps/add-expense/',
    name: 'apps-add-expense',
    component: () => import('@/views/apps/expense/ExpenseAdd.vue'),
    meta: {
      pageTitle: 'Add Expense',
      resource: 'expense',
      action: 'create',
      breadcrumb: [
        {
          text: 'Expense Tracker',
          to: { name: 'apps-expense-tracker' }
        },
        {
          text: 'Add Expense',
          active: true,
        },
        
      ],
    },
  },
  {
    path: '/apps/edit-expense/:id',
    name: 'apps-edit-expense',
    component: () => import('@/views/apps/expense/ExpenseEdit.vue'),
    meta: {
      pageTitle: 'Edit Expense',
      resource: 'expense',
      action: 'update',
      breadcrumb: [
        {
          text: 'Expense Tracker',
          to: { name: 'apps-expense-tracker' }
        },
        {
          text: 'Edit Expense',
          active: true,
        },
        
      ],
    },
  },
  {
    path: '/apps/client-recruiters-list/',
    name: 'apps-client-recruiters-list',
    component: () => import('@/views/apps/client/ClientRecruiterList.vue'),
    meta: {
      pageTitle: 'Client Recruiters List',
      resource: 'client_recruiter',
      breadcrumb: [
        {
          text: 'Client Recruiters List',
          to: { name: 'Client Recruiters List' }
        }        
      ],
    },
  },
  {
    path: '/apps/candidate-evaluation/',
    name: 'apps-candidate-evaluation',
    component: () => import('@/views/apps/candidate/candidate-evaluation/CandidateEvaluation.vue'),
    meta: {
      pageTitle: 'Candidate Evaluation',
      // resource: 'Candidate Evaluation',
          
      
    },
  },
  {
    path: '/apps/user/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Manage Users',
      resource: 'users',
      breadcrumb: [
        {
          text: 'Manager Users',
          active: true,
        },
        
      ],
    },
  },
  {
    path: '/apps/user/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    meta: {
      pageTitle: 'Manage Users',
      resource: 'users',
      breadcrumb: [
        {
          text: 'Manage Users',
          to: { name: 'apps-users-list' }
        },
        {
          text: 'Edit User',
          active: true,
        },
        
      ],
    },
  },
  {
    path: '/apps/user/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      pageTitle: 'Manage Users',
      resource: 'users',
      breadcrumb: [
        {
          text: 'Manage Users',
          to: { name: 'apps-users-list' }
        },
        {
          text: 'View User',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/user/probation/:emp_id',
    name: 'apps-users-probation',
    component: () => import('@/views/apps/user/users-view/UsersProbation.vue'),
    meta: {
      pageTitle: 'Manage Users',
      resource: 'users',
      breadcrumb: [
        {
          text: 'Manage Users',
          to: { name: 'apps-users-list' }
        },
        {
          text: 'User Probation',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vendor-registration',
    name: 'VendorRegistration',
    component: () => import('@/views/apps/vendor/VendorRegistration.vue'),
  },
  {
    path: '/vendor-registration/:vendorId',
    name: 'EditVendor',
    component: () => import('@/views/apps/vendor/VendorRegistration.vue'),
    props: true,
  },
  
  // {
  //   path: '/apps/approve-expense/',
  //   name: 'apps-approve-expense',
  //   component: () => import('@/views/apps/expense/ExpenseApproval.vue'),
  //   meta: {
  //     pageTitle: 'Approve Expense',
  //     resource: 'Approve Expense',
  //     breadcrumb: [
  //       {
  //         text: 'Expense Tracker',
  //         to: { name: 'apps-expense-tracker' }
  //       },
  //       {
  //         text: 'Approve Expense',
  //         active: true,
  //       },
        
  //     ],
  //   },
  // },
]
